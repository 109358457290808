<template lang="pug">
  v-layout(align-center justify-center class="mx-auto pa-0 ma-0 loginBase")
    div(id="overlay")
    v-card(
        ref="form"
        width="350" 
        elevation="8" 
        class="pa-3"
        v-show="!cardConfigServer")
      v-flex(style="display: flex")
        v-container(style="width: 38%")
          v-img(:src="require('./../assets/folha-icon.png')" 
                width="75" 
                class="ma-4 mt-1 mb-0")
      v-card-text(class="pt-0")
        v-layout(align-center justify-center)
          div(style="font-size: 25px;" class="pa-4 primary--text") CS Integrador
        v-layout(align-center justify-center)
          div(style="font-size: 13px; text-align: center;" class="ma-0 mt-1 mb-4")
            | Faça login com seu CNPJ e senha

        v-form(@submit.prevent="handleSubmit" ref="form")
          v-text-field(label="CNPJ"
                        name="usuario"
                        v-model="usuario"
                        prepend-inner-icon="mdi-account"
                        required
                        hide-details
                        color="primary"
                        class="mt-4")
          v-text-field(label="Senha"
                        name="senha"
                        v-model="senha"
                        type="password"
                        prepend-inner-icon="mdi-key"
                        hide-details
                        required
                        color="primary"
                        class="mt-4")
          v-layout(class="mt-6 mb-2")
            v-btn(type="submit" color="primary" dark outlined width="295") Entrar

    CoreLoader(v-if="loggingIn")

</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    usuario: '',
    senha: '',
    enviado: false,
    cardConfigServer: false,
    servidor: '',
    validandoServidor: false
  }),
  methods: {
    handleSubmit() {
      this.enviado = true
      const { usuario, senha } = this
      if (usuario && senha) {
        this.$store.dispatch('auth/login', { client_id: usuario, client_secret: senha })
      } else {
        this.$store.dispatch('alert/warning', 'Necessário informar o usuário e senha', { root: true })
      }
    },
  },
  computed: {
    loggingIn() {
      return !!this.$store.state.auth.user
    }
  },
  created() {
    this.$store.dispatch('auth/checkLogin')
  }
}
</script>

<style scoped>
.loginBase {
  position:fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  background:url('./../assets/bg.jpg');
  background-size:cover;
  background-position:top;
}
#overlay{
  background-color:rgba(0,40,0,.35);
  width:100%;
  height:100%;
  position:absolute;
}
</style>
